.ct-alert-box
  position: fixed
  bottom: 0
  left: 10px
  width: 300px
  max-width: 90%
  opacity: 0

  &.show-alert-box
    opacity: 1
    -webkit-animation: fadein 0.5s
    animation: fadein 0.5s

  &.hide-alert-box
    -webkit-animation: fadeout 0.5s
    animation: fadeout 0.5s

@-webkit-keyframes fadein
  from
    bottom: -30px
    opacity: 0
  to
    bottom: 0
    opacity: 1

@keyframes fadein
  from
    bottom: -30px
    opacity: 0
  to
    bottom: 0
    opacity: 1

@-webkit-keyframes fadeout
  from
    bottom: 0
    opacity: 1
  to
    bottom: -30px
    opacity: 0

@keyframes fadeout
  from
    bottom: 0
    opacity: 1
  to
    bottom: -30px
    opacity: 0
