.list-group-header.with-button
  display: flex
  justify-content: space-between

.heading-center-text
  margin: 0
  display: flex
  justify-content: center
  align-items: center
  text-align: center


.modal-kitchen-hours
  .modal
    .modal-dialog
      max-width: 600px
  .btn
    cursor: pointer
