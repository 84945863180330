body
  margin: 0
  font-family: sans-serif

.pb-container
  display: inline-block
  text-align: center
  width: 100%

  &.disabled .pb-button
    cursor: not-allowed

  &.loading .pb-button
    width: 26px
    border-width: 6.5px
    border-color: #ddd
    cursor: wait
    background-color: transparent
    padding: 0

    span
      transition: all 0.15s
      opacity: 0
      display: none

    .pb-progress-circle > path
      transition: opacity 0.15s 0.3s
      opacity: 1

  &.success .pb-button
    border-color: #A0D468
    background-color: #A0D468

    span
      transition: all 0.15s
      opacity: 0
      display: none

    .pb-checkmark > path
      opacity: 1

  &.error .pb-button
    border-color: #ED5565
    background-color: #ED5565

    span
      transition: all 0.15s
      opacity: 0
      display: none

    .pb-cross > path
      opacity: 1

  .pb-button
    background: transparent
    border: none
    color: currentColor
    cursor: pointer
    text-decoration: none
    text-align: center
    height: 26px
    width: 100%
    -webkit-tap-highlight-color: transparent
    outline: none
    transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s

    span
      display: inherit
      transition: opacity 0.3s 0.1s

    svg
      height: 26px
      width: 26px
      position: absolute
      transform: translate(-50%, -50%)
      pointer-events: none

      &.pb-checkmark path,
      &.pb-cross path
        stroke: #fff
        stroke-linecap: round
        stroke-width: 4

      &.pb-progress-circle
        animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710)

        path
          stroke: currentColor
          stroke-width: 5

    svg path
      opacity: 0
      fill: none
@keyframes spin
  from
    transform: translate(-50%, -50%) rotate(0deg)
    transform-origin: center center
  to
    transform: translate(-50%, -50%) rotate(360deg)
    transform-origin: center center

.react-pagination
  display: table
  margin: auto
  margin-top: 40px
  margin-bottom: 20px

  li
    float: left
    padding: 10px
    list-style-type: none

    &.selected
      background: #F25F23

      a
        color: white

    a
      color: black

  .previous-link
    color: black

  .next-link
    color: black

  .break
    float: left
    padding: 10px
    list-style-type: none

.split
  -webkit-box-sizing: border-box
     -moz-box-sizing: border-box
          box-sizing: border-box

thead th
  &.sortable
    cursor: pointer
    background-image: url('data:image/pngbase64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC')
    background-position: right
    background-repeat: no-repeat
    padding-right: 30px

    &.sort-asc
      background-image: url('data:image/pngbase64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==')

    &.sort-desc
      background-image: url('data:image/pngbase64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=')

.dropdown-menu .dropdown-item
  cursor: pointer

  &:hover
    background-color: #d7d7d9

  &.mb-2
    margin-bottom: 0 !important
    padding: 0.25rem 1.5rem

.copy-button
  border: none
  background-color: transparent

.map-button-container
  position: absolute
  right: 5px
  z-index: 4

  &.top
    top: 5px

  &.bottom
    bottom: 5px