.teams-search-layout
  tr td
    vertical-align: middle

  .btn
    cursor: pointer

  .table button.btn-link
    padding-left: 0

  .modal
    .btn
      cursor: pointer

  .modal-team-edit .modal-body
    overflow: visible !important

  .modal-body .error
    color: red

  .modal-team-members .modal-body
    padding: 25px
