.gmaps-error
  color: #8c0000

.geosuggest
  text-align: left
  position: relative
  margin-left: auto
  margin-right: auto

  input[type=text]
    width: 100%
    background-image: url(../../img/icons-location.svg)
    background-repeat: no-repeat
    background-position: 12px
    padding: 0.5em 0.85714em 0.5em 2.28571em
    margin: 0
    height: 2.25rem

.geosuggest__suggests
  position: absolute
  top: 100%
  left: 0
  right: 0
  max-height: 25em
  padding: 0
  margin-top: -1px
  background: #fff
  border: 1px solid #F25F23
  border-top-width: 0
  overflow-x: hidden
  overflow-y: auto
  list-style: none
  z-index: 5

.geosuggest__suggests--hidden
  max-height: 0
  overflow: hidden
  border-width: 0

/**
 * A geosuggest item
 */
.geosuggest__item
  font-size: 18px
  font-size: 1rem
  padding: .5em .65em
  cursor: pointer

  &:hover,
  &:focus
    background: #f5f5f5

.geosuggest__item--active
  background: #267dc0
  color: #fff

  &:hover,
  &:focus
    background: #ccc

.changeLocation
  margin-top: 10px
  color: #F25F23
  font-size: 11px
